import styled from "styled-components"


export const LayoutDiploma = styled.div`
 * {
    padding:0;
    margin:0;
    vertical-align:baseline;
    list-style:none;
    border:0
}
    display: flex;
    align-items: stretch;
    justify-content: start;
    
`

export const ContainerDiploma = styled.div`
    padding: 50px;
    

`
export const TituloDiploma = styled.h1`
    font-size: 25px;
    margin-bottom: 15px;
`

export const TableDiploma = styled.table`
    
    tr{
        padding: 10px;
        width: fit-content;
        text-align: center;
        &:nth-child(even){
        background-color: lightblue;
        }
    }
    th{
        font-size: 13px;
        font-weight: 500;
        padding: 10px;
        background-color: rgb(231, 233, 235);
        color: black;
    }
    td{
        font-size: 13px;
        padding:15px;
        background-color: white;
        margin: 10px;
        a{  
            padding: 10px;
            color: green;
            font-weight: 400;
            background-color:transparent;
            width: 100%;
            border-radius: 3px;
            text-decoration: underline;
            justify-content: center;
            &:hover{
                cursor: pointer;
                opacity: 0.6;
            }
        }
    }
`