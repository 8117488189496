import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutCadastroAfastamentoStyle, ContainerCadastroAfastamentoStyle, DataStyle, BotaoAfastamentoStyle } from "./style.js";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import { useToken } from "../../contexts/useToken";

function CadastroAfastamento() {
  const { token } = useToken();
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const [afastamento, setAfastamento] = useState({
    idUsuario: "",
    numPortaria: "",
    areaEstudo: "",
    instAfastamento: "",
    dataInicio: "",
    dataFim: "",
  });

  const [users, setUsers] = useState([]);

  const onChange = (e) => {
    setAfastamento({ ...afastamento, [e.target.name]: e.target.value });
  };

  //Hook
  const Cadastro = async (e) => {
    e.preventDefault(); //Não vai atualizar a tela desnecessariamente
    await api
      .post("/afastamento/afastamento/", afastamento, token)
      .then((response) => {
        toast.success(response.data.msg);
        setAfastamento({
          idUsuario: "",
          numPortaria: "",
          areaEstudo: "",
          instAfastamento: "",
          dataInicio: "",
          dataFim: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde!");
        }
      });
  };

  useEffect(() => {
    async function getData() {
      await api.get("/usuario/servidor", token).then((response) => {
        setUsers(response.data);
        setLoadingData(false);
      });
    }
    if (loadingData) {
      getData();
    }
  }, [loadingData, token]);

  return (
    <LayoutCadastroAfastamentoStyle>
      <ContainerCadastroAfastamentoStyle>
        <form onSubmit={Cadastro}>
          <h1> Cadastrar/Alterar Afastamento </h1>

          <label htmlFor="idUsuario">Servidor: </label>
          <select name="idUsuario" value={afastamento.idUsuario} onChange={onChange}>
            <option> Selecione uma opção </option>
            {users.map((option) => (
              <option value={option.id} key={option.id}>{`${option.nome} `}</option>
            ))}
          </select>

          <label htmlFor="numPortaria">Portaria de concessão: </label>
          <input type="text" id="numPortaria" name="numPortaria" placeholder="Insira a portaria" value={afastamento.numPortaria} onChange={onChange} />

          <label htmlFor="areaEstudo">Área da pós-graduação: </label>
          <input type="text" id="areaEstudo" name="areaEstudo" placeholder="Insira a área de pós-graduação" value={afastamento.areaEstudo} onChange={onChange} />

          <label htmlFor="instAfastamento">Instituição: </label>
          <input type="text" id="instAfastamento" name="instAfastamento" placeholder="Insira o nome da Instituição" value={afastamento.instAfastamento} onChange={onChange} />

          <DataStyle>
            <label>Período de afastamento: </label>

            <input type="date" id="dataInicio" name="dataInicio" value={afastamento.dataInicio} onChange={onChange} />

            <p>a</p>

            <input type="date" id="dataFim" name="dataFim" value={afastamento.dataFim} onChange={onChange} />
          </DataStyle>
          <BotaoAfastamentoStyle>
            <button
              onClick={() => {
                navigate("/main");
              }}
            >
              Voltar
            </button>
            <button>Cadastrar</button>
          </BotaoAfastamentoStyle>
        </form>
      </ContainerCadastroAfastamentoStyle>
    </LayoutCadastroAfastamentoStyle>
  );
}
export default CadastroAfastamento;
