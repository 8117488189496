import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import { LayoutCadastroSetorStyle, ContainerCadastroSetorStyle, TableSetor, BotaoDiv } from "./style.js";
import { toast } from "react-toastify";
import { useToken } from "../../contexts/useToken";

function CadastroSetor() {
  const { token } = useToken();
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const [setores, setSetores] = useState([]);

  const [setor, setSetor] = useState({
    setorLotacao: "",
    siglaSetor: "",
  });

  const onChange = (e) => {
    setSetor({ ...setor, [e.target.name]: e.target.value });
  };

  const NovoSetor = async (e) => {
    e.preventDefault();
    await api
      .post("/afastamento/setores", setor, token)
      .then((response) => {
        toast.success(response.data.msg);
        setSetor({
          setorLotacao: "",
          siglaSetor: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde!");
        }
      });
  };

  useEffect(() => {
    const getData = async () => {
      await api.get("/afastamento/setores", token).then((response) => {
        setSetores(response.data);
        setLoadingData(false);
      });
    };
    if (loadingData) {
      getData();
    }
  }, [loadingData, token]);

  return (
    <LayoutCadastroSetorStyle>
      <ContainerCadastroSetorStyle>
        <form onSubmit={NovoSetor}>
          <h1>Cadastro de Setor</h1>
          <p>
            Antes de realizar o cadastro, verifique na tabela abaixo se o setor já está cadastrado. Caso já esteja cadastrado, avance para a página de <strong>Cadastro de Usuário</strong>
          </p>
          <label htmlFor="setorLotacao">
            Setor de lotação:
            <input type="text" id="setorLotacao" name="setorLotacao" placeholder="Insira o novo setor de lotação" value={setor.setorLotacao} onChange={onChange} />
          </label>

          <label htmlFor="siglaSetor">Sigla do setor de lotação</label>
          <input type="text" id="siglaSetor" name="siglaSetor" placeholder="Insira o novo setor de lotação" value={setor.siglaSetor} onChange={onChange} />

          <BotaoDiv>
            <button type="submit"> Cadastrar </button>
            <button
              onClick={() => {
                navigate("cadastro-usuario");
              }}
            >
              Ir para a Página de Cadastro de Usuário
            </button>
          </BotaoDiv>

          <h4>Setores Cadastrados</h4>

          <TableSetor>
            <thead>
              <tr>
                <th>Sigla</th>
                <th>Setor</th>
              </tr>
            </thead>
            <tbody>
              {setores.map((lotacao) => (
                <tr key={lotacao.id}>
                  <td>{lotacao.siglaSetor}</td>
                  <td>{lotacao.setorLotacao}</td>
                </tr>
              ))}
            </tbody>
          </TableSetor>
        </form>

        <BotaoDiv></BotaoDiv>
      </ContainerCadastroSetorStyle>
    </LayoutCadastroSetorStyle>
  );
}

export default CadastroSetor;
