/* eslint-disable */

import {AiOutlineLink} from 'react-icons/ai';
import {LayoutFinaliza,ContainerFinaliza, TituloFinaliza, TabelaFinaliza} from "./style.js";
function FinalizaProcesso(){
    return(
        <>
            <LayoutFinaliza>
                <ContainerFinaliza>
                    <TituloFinaliza>
                        <h1>Processos Finalizados</h1>
                    </TituloFinaliza>
                    <TabelaFinaliza>
                        <table>
                            <tr>
                                <th>Servidor afastado</th>
                                <th>Relatório 1</th>
                                <th>Relatório 2</th>
                                <th>Relatório 3</th>
                                <th>Relatório 4</th>
                                <th>Diploma</th>
                            </tr>
                            <tr>
                                <td>JOÃO GOMES DA SILVA RIBEIRO</td>
                                <td><a><AiOutlineLink/> Relatorio 1</a></td>
                                <td><a><AiOutlineLink/> Relatorio 2</a></td>
                                <td><a><AiOutlineLink/> Relatorio 3</a></td>
                                <td><a><AiOutlineLink/> Relatorio 4</a></td>
                                <td><a><AiOutlineLink/> Diploma </a></td>
                            </tr>
                            <tr>
                                <td>ALEXANDRA FERREIRA DE LIMA</td>
                                <td><a> <AiOutlineLink/>Relatorio 1</a></td>
                                <td><a><AiOutlineLink/> Relatorio 2</a></td>
                                <td><a><AiOutlineLink/> Relatorio 3</a></td>
                                <td><a><AiOutlineLink/> Relatorio 4</a></td>
                                <td><a><AiOutlineLink/> Diploma</a> </td>
                            </tr>
                        </table>
                    </TabelaFinaliza>
                </ContainerFinaliza>
            </LayoutFinaliza>
            
            
        </>
        
    );
}
export default FinalizaProcesso;