import React, { useContext } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import Login from '../pages/login'
import Main from '../pages/main'
import CadastroAfastamento from '../pages/cadastroafastamento';
import PageNotFound from '../pages/pagenotfound';
import AcompanhaRelatorio from '../pages/acompanharelatorio';
import AcompanhaDiploma from '../pages/acompanhadiploma';
import CadastroUsuario from '../pages/cadastrousuario';
import PaginaServidor from '../pages/servidorpagina';
import VisualizaRelatorio from '../pages/visualizarelatorio';
import FinalizaProcesso from '../pages/finalizaprocesso';
import AlteraSenha from '../pages/alterasenha';
import CadastroSetor from '../pages/cadastrosetor'

import { AuthProvider, AuthContext } from "../contexts/auth"


function Root() {
    const Private = ({ children, permission }) => {
        const { authenticated, loading, hasPermission } = useContext(AuthContext);
        if (loading) { return <div>Carregando...</div> };
        if (!authenticated) { return <Navigate to="/" /> }
        return (hasPermission(permission))
            ? children
            : <Navigate to="/main" />
    };

    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    {/*Página Pública*/}
                    <Route path="/" element={<Login />} />


                    {/*Páginas privadas*/}
                    <Route path="main" element={<Private><Main /></Private>}>
                          <Route path="cadastro-setor" element={<Private permission={["gestor"]}><CadastroSetor /></Private>}/>
                            
                      
                        <Route path="cadastro-setor/cadastro-usuario" element={<Private permission={["gestor"]} ><CadastroUsuario /></Private>} />
                        <Route path="cadastro-afastamento" element={<Private permission={["gestor"]}><CadastroAfastamento /></Private>} />

                      

                        <Route path="acompanhamento-de-relatorio" element={<Private permission={["gestor", "chefia"]}><AcompanhaRelatorio/></Private>} />

                        <Route path="acompanhamento-de-diploma" element={<Private permission={["gestor", "chefia"]} ><AcompanhaDiploma /></Private>} />

                        <Route path="visualiza-relatorio/:id" element={<Private permission={["gestor", "chefia"]} ><VisualizaRelatorio /></Private>} />

                        <Route path="pagina-servidor" element={<Private permission={["servidor"]}><PaginaServidor /></Private>} />

                        <Route path="finaliza-processo" element={<Private permission={["gestor", "chefia"]} ><FinalizaProcesso /></Private>} />

                        <Route path="alterar-senha" element={<Private permission={["gestor", "chefia", "servidor"]}><AlteraSenha /></Private>} />

                    </Route>

                    <Route path="/main" element={<PageNotFound />} />
                </Routes>
            </AuthProvider>

        </BrowserRouter>
    );
};

export default Root;