import { useState, useEffect, createContext } from "react";

import { useNavigate } from "react-router-dom";
import { api } from "../services/api";
import { decodedToken } from "../services/util";
import { toast } from "react-toastify";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [isLogged, setIsLogged] = useState(null);
  const [name, setName] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  const [token, setToken] = useState(null);

  useEffect(() => {
    if (!token) return;
    localStorage.setItem("token", token);
  }, [token]);

  useEffect(() => {
    const recoveredUser = localStorage.getItem("token");
    //validar token
    if (recoveredUser) {
      const getByToken = decodedToken(recoveredUser);
      setIsLogged(true);
      setId(getByToken.id);
      setName(getByToken.name);
      setRole(getByToken.role);
      setToken(recoveredUser);
    }
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    let data = { email, senha: password };

    await api
      .post("/usuario/login", data)
      .then((response) => {
        const token = response.data.token;
        setToken(token);

        const getByToken = decodedToken(token);
        setIsLogged(true);
        setId(getByToken.id);
        setName(getByToken.name);
        setRole(getByToken.role);
        navigate("/main");
        toast.success("Bem vindo ao PGCA.");
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde!");
        }
      });
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const hasPermission = (roleNecessary) => {
    if (!role) {
      return false;
    }
    if (!roleNecessary) {
      return true;
    }

    if (Array.isArray(roleNecessary)) {
      return roleNecessary.filter((item) => item === role).length > 0;
    }

    return role === roleNecessary;
  };

  return <AuthContext.Provider value={{ authenticated: !!isLogged, user: isLogged, token, id, role, name, loading, hasPermission, login, logout }}>{children}</AuthContext.Provider>;
};
