import styled from "styled-components"


export const LayoutStyleSideBar = styled.div`
    display: flex;
    flex: 1;
    align-items: stretch;
    flex-direction: row;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;



export const SideBarStyle = styled.div`
    display: flex;
    flex-direction: column;
    color:rgb(46, 68, 78);
    box-shadow: black 10px solid;
    border: rgb(239, 239, 239) solid 1px;
    flex: 0 1 20vw;
    img{
        height: 30%;
        width: 30%;
        object-fit: contain;
        margin-bottom: 10px;
        &:hover{
            
        }
        
    }
`;
//rgb(46, 68, 78);rgb(239, 239, 239)
//Profile
export const SideBarProfileStyle = styled.div`
    height: fit-content;
    padding: 20px 10px;
    font-size:10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    justify-content: start;
`;

export const ProfileStyle = styled.div`
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    align-items: stretch;
    background-color: rgb(239, 239, 239);
    height: fit-content;
    padding: 12px;
    border-radius: 10px;
    color: rgb(105,0,0);
    h1{
        font-size: 15px;
    }
    p{
        font-size: 10px;
    }
    
`;


//Links
export const SideBarLinksStyle = styled.nav`
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 20px 10px;
    
    h1{
        font-size: 15px;
        color: rgb(105,0,0);
        padding: 0 12px;
        margin-bottom: 10px;
    };
    button{
        margin: 5px;
        padding: 15px;
        width: 100%;
        text-align: left;
        border-radius: 10px;
        color: #690000;
        background-color: rgb(239, 239, 239);
        //color:rgb(46, 68, 78);
        &:hover{
            cursor: pointer;
            background-color: rgb(105,0,0,0.2) ;
            color:#690000;
            
        }
        
    }
`;
export const ConfiguracaoStyle  = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-top: 15px;
    a{
      text-decoration: none;
      margin-bottom: 10px;
      color:#690000;
      &:hover{
          cursor: pointer;
          color: gray;
          font-weight: 600rem;
      }
    }
    
    color: gray;
`;

export const ContentStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
    justify-content: start;
    align-items: stretch;
    background-color: #fafafa;
    padding: 20px
`;