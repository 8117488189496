/* eslint-disable */

import { useState, useEffect } from "react";
import { LayoutNovoUsuarioStyle, FormNovoUsuarioStyle, BotaoCadastroSyle } from "./style.js";
import { useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import { useToken } from "../../contexts/useToken";

function CadastroUsuario() {
  const { token } = useToken();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    nomeusuario: "",
    usuario: "",
    emailusuario: "",
    senhausuario: "",
    tipousuario: "",
    setorusuario: "",
  });
  const [loadingData, setLoadingData] = useState(true);
  const [setores, setSetores] = useState([]);
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const NovoUsuario = async (e) => {
    e.preventDefault();
    await api
      .post("/usuario/register", user, token)
      .then((response) => {
        toast.success(response.data.msg);
        setUser({
          nomeusuario: "",
          usuario: "",
          emailusuario: "",
          senhausuario: "",
          tipousuario: "",
          setorusuario: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde!");
        }
      });
  };

  useEffect(() => {
    async function getData() {
      await api.get("/afastamento/setores", token).then((response) => {
        setSetores(response.data);
        setLoadingData(false);
      });
    }
    if (loadingData) {
      getData();
    }
  }, []);

  return (
    <LayoutNovoUsuarioStyle>
      <FormNovoUsuarioStyle>
        <form onSubmit={NovoUsuario}>
          <h1>Cadastrar Usuário</h1>

          <label htmlFor="nomeusuario">
            {" "}
            Nome:
            <input type="text" id="nomeusuario" name="nomeusuario" placeholder="Insira o nome do usuário" value={user.nomeusuario} onChange={onChange} />
          </label>

          <label htmlFor="usuario"> Usuário: </label>
          <input type="text" id="usuario" name="usuario" placeholder="Insira o usuário" value={user.usuario} onChange={onChange} />

          <label htmlFor="email"> E-mail: </label>
          <input type="email" id="emailusuario" name="emailusuario" placeholder="Insira o e-mail do usuário" value={user.emailusuario} onChange={onChange} />

          <label htmlFor="senhausuario"> Senha: </label>
          <input type="password" id="senhausuario" name="senhausuario" placeholder="Insira a senha" value={user.senhausuario} onChange={onChange} />

          <label>Tipo de usuário: </label>
          <select name="tipousuario" value={user.tipousuario} onChange={onChange}>
            <option> Selecione uma opção </option>
            <option value="servidor"> Servidor </option>
            <option value="chefia"> Chefia </option>
            <option value="gestor"> Gestor </option>
          </select>

          <label> Selecione um setor:</label>
          <select name="setorusuario" value={user.setorusuario} onChange={onChange}>
            <option> Selecione uma opção </option>
            {setores.map((option) => (
              <option value={option.id} key={option.id}>{`${option.siglaSetor} - ${option.setorLotacao}`}</option>
            ))}
          </select>

          <BotaoCadastroSyle>
            <button
              onClick={() => {
                navigate("/main/cadastro-setor");
              }}
            >
              Voltar
            </button>
            <button>Cadastrar</button>
          </BotaoCadastroSyle>
        </form>
      </FormNovoUsuarioStyle>
    </LayoutNovoUsuarioStyle>
  );
}
export default CadastroUsuario;

{
  /*<label>Setor de lotação: </label>
                    <select 
                    name="setorusuario" 
                    placeholder="Selecione uma opção"
                    value={setorusuario}
                    onChange={(e) => setSetorUsuario(e.target.value)}>
                        <option value="PROEN"> PROEN </option>
                        <option value="PROAS"> PROAS </option>
                    </select> */
}

// fetch("http://localhost:3001/api/afastamento/setores", {
//     method: "GET",
//     headers: {
//         'Content-Type': 'application/json'
//     }
// })
//     .then((resp) => resp.json()) //o que eu receber de dado vai virar json
//     .then((data) => { setSetores(data); })
//     .catch((err) => console.error(err));
