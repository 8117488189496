import dayjs from "dayjs";

export const decodedToken = (token) => {
  const payload = token.split(".")[1];
  return JSON.parse(atob(payload));
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};


export const dueDate = (due) => {
  const today = dayjs(new Date());
  const data = `${(due).split('/')[2]}-${(due).split('/')[1]}-${(due).split('/')[0]}`
  return dayjs(data).isBefore(today);
};

