import styled from "styled-components"


export const LayoutServidor = styled.div`
 * {
    padding:0;
    margin:0;
    vertical-align:baseline;
    list-style:none;
    border:0
}
    display: flex;
    align-items: stretch;
    justify-content: start;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;

`
export const ContainerServidor = styled.div`
    padding: 50px;
`
export const TituloServidorRelatorio = styled.div`
    font-size: 13px;
    margin-bottom: 15px;
`
export const TituloServidorDiploma = styled.div`
    font-size: 12px;
    margin: 30px 0 15px 0;
`
export const BoxServidor = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
export const RelatorioServidor = styled.div`
    padding: 40px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 5px 10px 5px;
    background-color: white;
    width: 100%;
    height: 100%;
    border: 1px solid lightgray;
    border-radius: 3px;
    justify-content: space-between;
    align-items: stretch;
    text-align: center;
    h4{
        margin-bottom: 40px;
        font-size: 17px;
    }
    label{
    background-color:#31B956;
    color:white;
    padding: 10px;
    font-size: 0.9em;
    font-weight: 700;
    border-radius: 3px;
    &:hover{
        cursor:pointer;
        opacity:0.6;  
        }
    }
    input{
    display:none;
    }
    
 `   
    export const PreviewArquivo = styled.div`
        display: flex;
        margin-top:20px ;
        padding: 10px;
        
        img{
            width: 25px;
            height: 25px;
        }
        p{
                font-size: 0.6em;
                text-align: left;
                margin-left: 2px;
            }
    `

    
    

    

export const RelatorioBotao = styled.div`
    display: flex;
    justify-content:center;
    align-items:stretch;
    width: 100%;
    padding: 20px 0 0;
    
    button{
        width: 100%;
        padding: 10px ;
        font-size: 0.9em; 
        border: 2px solid rgb(90,2,31);
        border-radius:0 0 3px 3px;
        color: white;
        background-color: rgb(90,2,31);
        font-weight: bold;
        
        &:hover{
            cursor: pointer;
            opacity: 0.6;
        }
    }  
`
export const BotaoServidor = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 15px;
    a{  
        margin-top: 20px;
        padding: 10px;
        border-radius: 3px;
        font-size: 12px;
        background-color: rgb(90,2,31);
        color: white;
        width: fit-content;
        margin-right: 5px;
        text-decoration: none;
        &:hover{
        cursor: pointer;
        background-color: rgb(90,2,31, 0.9);
    }
    }
`

export const LinkStyle = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 7px;
    a{
       margin-top: 6px;
       background-color: blue;
       color: white;
       padding: 5px;
       border-radius: 3px;
       font-size: 0.8rem;
       text-decoration: none;
       &:hover{
        cursor: pointer;
        opacity: 0.6;
    
        }
        
    }
`