/* eslint-disable */
import React, { useContext } from 'react';
import { useNavigate, Outlet } from "react-router-dom";
import { LayoutStyleSideBar, SideBarStyle, SideBarLinksStyle, SideBarProfileStyle, ProfileStyle, ConfiguracaoStyle, ContentStyle } from "./style.js"
import iconpgca from "../../assets/iconPGCA.png";
import { AuthContext } from "../../contexts/auth"
function Main() {
    let navigate = useNavigate();

    const { name, role, logout, hasPermission } = useContext(AuthContext);

    const handleLogout = () => {
        logout();
    };

    return (
        <LayoutStyleSideBar>

            <SideBarStyle>

                <SideBarProfileStyle>
                    <img src={iconpgca} alt="Ícone da PGCA" />
                    <ProfileStyle>
                        <h1>{name}</h1>
                        <p>{role}</p>
                    </ProfileStyle>
                    <ConfiguracaoStyle>
                        <a onClick={() => { navigate("alterar-senha") }}>Configurações</a>
                        <a onClick={handleLogout}>Sair</a>
                    </ConfiguracaoStyle>
                </SideBarProfileStyle>

                <SideBarLinksStyle>
                    <h1>Menu</h1>

                    {<button onClick={() => { navigate("/main") }}>Pagina Inicial</button>}

                    {hasPermission(["gestor"]) && <button onClick={() => { navigate("cadastro-setor") }}>Cadastro de usuário</button>}

                    {hasPermission(["gestor"]) && <button onClick={() => { navigate("cadastro-afastamento") }}>Cadastro de afastamento</button>}

                    {hasPermission(["gestor","chefia"]) && <button onClick={() => { navigate("acompanhamento-de-relatorio") }}>Acompanhar entrega de relatório de atividade</button>}

                    {hasPermission(["gestor","chefia"]) && <button onClick={() => { navigate("acompanhamento-de-diploma") }}>Acompanhar entrega de diploma</button>}

                    {hasPermission(["servidor"]) && <button onClick={() => { navigate("pagina-servidor") }}>Servidor</button>}

                    {//{hasPermission(["gestor"]) && <button onClick={() => { navigate("finaliza-processo") }}>Processos Finalizados</button>}
}
                </SideBarLinksStyle>
            </SideBarStyle>

            <ContentStyle>
                <Outlet />
            </ContentStyle>
        </LayoutStyleSideBar>
    )
}
export default Main;