import styled from "styled-components";

export const LayoutVisualizaRelatorio = styled.div`
  * {
    padding: 0;
    margin: 0;
    vertical-align: baseline;
    list-style: none;
    border: 0;
  }
  display: flex;
  align-items: stretch;
  justify-content: start;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
`;
export const ContainerVisualizaRelatorio = styled.div`
  padding: 50px;
  width: 80%;
  height: auto;
`;
export const TituloVisualizaRelatorio = styled.div`
  font-size: 12px;
  margin-bottom: 50px;
`;
export const ContainerPDF = styled.div``;
export const ContainerTexto = styled.div`
  margin: 20px 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  label {
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: bold;
  }
`;
export const TextoComentario = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 10px 0;

  label {
    margin: 0;
    padding: 0;
  }

  textarea {
    outline: none;
    padding: 10px;
    border-radius: 3px;
    margin: 10px 0;

    background-color: whitesmoke;
    font-size: 13px;
    margin-top: 8px;
  }
`;
export const TextoArquivo = styled.div`
  font-weight: bold;
  flex: 1;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;
export const TextoStatus = styled.div`
  font-weight: bold;
  margin: 10px 0;
  label {
    display: block;
  }
  select {
    outline: none;
    padding: 10px;
    border-radius: 3px;
  }
`;
export const ContainerDetalhes = styled.div`
  width: fit-content;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  height: fit-content;
  padding: 10px;
  margin-bottom: 10px;
  h4 {
    font-size: 15px;
    text-align: center;
    margin-bottom: 15px;
  }
  tr {
    font-size: 15px;
    text-align: left;
  }
  td {
    padding: 10px;
  }
  th {
    background-color: lightgray;
    color: black;
    padding: 10px;
  }
`;
export const TextoBotao = styled.div`
  display: flex;
  justify-content: end;
  button {
    margin-top: 20px;
    padding: 10px;
    border-radius: 3px;
    font-size: 12px;
    color: white;
    width: fit-content;
    margin-right: 5px;
    background-color: rgb(90, 2, 31);
    &:hover {
      cursor: pointer;
      background-color: rgba(90, 2, 31, 0.9);
    }
  }
`;
//
