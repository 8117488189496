/* eslint-disable */
import React from 'react'
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutStyleLogin, BoxStyleLogin, ContainerLeftStyleLogin, ContainerRightStyleLogin, BotaoLoginStyle, ImagemPGCA } from './style.js';
import logopgca from "../../assets/logoPGCA.png";
import logoufma from "../../assets/logoufmacolorido.png";
import { AuthContext } from '../../contexts/auth.js';


export default function Login() {
    const { login } = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault(); //pare de fazer a ação padrão
        login(email, password); //integração com meu contexto/api
    };



    return (
        <LayoutStyleLogin>

            <ContainerLeftStyleLogin>

                <BoxStyleLogin>

                    <ImagemPGCA>
                        <img src={logopgca} alt="Logo da PGCA"></img>
                    </ImagemPGCA>

                    <h2>Seja bem-vindo </h2>
                
                    <p>Realize seu login para prosseguir</p>

                    <form onSubmit={handleSubmit}>

                        <label htmlFor="email">Email: </label>
                        <input  type="email"
                            name="email"
                            id="email"
                            placeholder="Coloque seu e-mail"
                            value={email} onChange={(e) => setEmail(e.target.value)}
                        />

                        <label htmlFor="password">Senha: </label>
                        <input  type="password"
                            name="password"
                            id="password"
                            placeholder="Coloque sua senha"
                            value={password} onChange={(e) => setPassword(e.target.value)}
                        />

                        <BotaoLoginStyle>
                            <button type="submit" >
                                Acessar
                            </button>
                        </BotaoLoginStyle>

                    </form>


                </BoxStyleLogin>

            </ContainerLeftStyleLogin>

            <ContainerRightStyleLogin>
                <img src={logoufma} alt="Logo da Ufma" />
            </ContainerRightStyleLogin>

        </LayoutStyleLogin>
    )
}

