import './App.css';
import Root from './routes';
import {ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return(
    <>
      <Root />
      <ToastContainer/>
    </>
    
    
  );
};

export default App;


