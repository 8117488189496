import { LayoutDiploma, ContainerDiploma, TituloDiploma, TableDiploma } from "./style.js";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { api } from "../../services/api.js";
import { useToken } from "../../contexts/useToken";

function AcompanhaRelatorio() {
  const { token } = useToken();
  const [loadingData, setLoadingData] = useState(true);
  const [afastamentos, setAfastamentos] = useState([]);
  const [usuarios, setUsuario] = useState([]);

  useEffect(() => {
    async function getData() {
      await api.get("/usuario/servidor", token).then((response) => {
        setUsuario(response.data);
        setLoadingData(false);
      });

      await api.get("/afastamento/afastamento", token).then((response) => {
        setAfastamentos(response.data);
        setLoadingData(false);
      });
    }

    if (loadingData) {
      getData();
    }
  }, [loadingData, token]);

  const userNome = (id) => {
    const result = usuarios.find((usuario) => usuario.id === id);
    const nome = result?.nome;
    return nome;
  };

  return (
    <LayoutDiploma>
      <ContainerDiploma>
        <TituloDiploma>Acompanhar entrega de relatorios</TituloDiploma>

        <TableDiploma>
          <thead>
            <tr>
              <th>Servidor </th>
              <th>Relatorio 1 </th>
              <th>Relatorio 2 </th>
              <th>Relatorio 3 </th>
              <th>Relatorio 4 </th>
            </tr>
          </thead>
          <tbody>
            {afastamentos.map((afast) => (
              <tr key={afast.id}>
                <td>{userNome(afast.idUsuario)}</td>

                <td>
                  {afast.relatorio1 ? (
                    <Link to={`/main/visualiza-relatorio/${afast.relatorio1}`} style={{ color: "green" }}>
                      Relatorio 1
                    </Link>
                  ) : (
                    <p style={{ color: "red" }}> Relatorio 1</p>
                  )}
                </td>

                <td>
                  {afast.relatorio2 ? (
                    <Link to={`/main/visualiza-relatorio/${afast.relatorio2}`} style={{ color: "green" }}>
                      Relatorio 2
                    </Link>
                  ) : (
                    <p style={{ color: "red" }}> Relatorio 2</p>
                  )}
                </td>

                <td>
                  {afast.relatorio3 ? (
                    <Link to={`/main/visualiza-relatorio/${afast.relatorio3}`} style={{ color: "green" }}>
                      Relatorio 3
                    </Link>
                  ) : (
                    <p style={{ color: "red" }}> Relatorio 3</p>
                  )}
                </td>

                <td>
                  {afast.relatorio4 ? (
                    <Link to={`/main/visualiza-relatorio/${afast.relatorio4}`} style={{ color: "green" }}>
                      Relatorio 4
                    </Link>
                  ) : (
                    <p style={{ color: "red" }}> Relatorio 4</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </TableDiploma>
      </ContainerDiploma>
    </LayoutDiploma>
  );
}

export default AcompanhaRelatorio;
