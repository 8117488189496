import axios from "axios";

const baseAxiosService = axios.create({
  baseURL: "https://pgca.ufma.br/api",
});

const get = (url, token) => {
  return baseAxiosService.get(url, {
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
  });
};

const post = (url, data, token) => {
  return baseAxiosService.post(url, data, {
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
  });
};

const put = (url, data, token) => {
  return baseAxiosService.put(url, data, {
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
  });
};

export const api = {
  get,
  post,
  put,
};
