import React, { useState, useContext, useEffect, useCallback } from "react";
import { FiUpload } from "react-icons/fi";
import { AuthContext } from "../../contexts/auth";
import { api } from "../../services/api.js";
import { convertBase64, dueDate } from "../../services/util";
import { LayoutServidor, ContainerServidor, TituloServidorRelatorio, TituloServidorDiploma, RelatorioServidor, BotaoServidor, BoxServidor, RelatorioBotao, PreviewArquivo } from "./style.js";
import pdficon from "../../assets/pdficon.png";
import { toast } from "react-toastify";
import { useToken } from "../../contexts/useToken";

const PaginaServidor = () => {
  const { token } = useToken();
  const { id } = useContext(AuthContext);
  const [loadingData, setLoadingData] = useState(true);

  const [relatorio1, setRelatorio1] = useState("");
  const [relatorio2, setRelatorio2] = useState("");
  const [relatorio3, setRelatorio3] = useState("");
  const [relatorio4, setRelatorio4] = useState("");
  const [diploma, setDiploma] = useState("");
  const [afastInfo, setAfastInfo] = useState("");

  const getData = useCallback(async () => {
    await api.get(`/afastamento/afastamentoByid/${id}`, token).then((response) => {
      setAfastInfo(response.data);
      setLoadingData(false);
    });
  }, [id, token]);

  useEffect(() => {
    if (loadingData) {
      getData();
    }
  }, [loadingData, getData]);

  // Relatorio 1
  const uploadRelatorio1 = async (e) => {
    e.preventDefault();
    const file = await convertBase64(relatorio1);
    const data = { file };
    await api
      .post(`/documento/novoRel/${afastInfo.id}/1`, data, token)
      .then((response) => {
        getData();
        toast.success(response.data.msg);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde.");
        }
      });
  };
  // Relatorio 2
  const uploadRelatorio2 = async (e) => {
    e.preventDefault();
    const file = await convertBase64(relatorio2);
    const data = { file };
    await api
      .post(`/documento/novoRel/${afastInfo.id}/2`, data, token)
      .then((response) => {
        getData();
        toast.success(response.data.msg);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde.");
        }
      });
  };

  // Relatorio 3
  const uploadRelatorio3 = async (e) => {
    e.preventDefault();
    const file = await convertBase64(relatorio3);
    const data = { file };
    await api
      .post(`/documento/novoRel/${afastInfo.id}/3`, data, token)
      .then((response) => {
        getData();
        toast.success(response.data.msg);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde.");
        }
      });
  };

  // Relatorio 4
  const uploadRelatorio4 = async (e) => {
    e.preventDefault();
    const file = await convertBase64(relatorio4);
    const data = { file };
    await api
      .post(`/documento/novoRel/${afastInfo.id}/4`, data, token)
      .then((response) => {
        getData();
        toast.success(response.data.msg);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde.");
        }
      });
  };

  // Diploma
  const uploadDiploma = async (e) => {
    e.preventDefault();

    console.log(afastInfo.id);
    console.log(id);

    const file = await convertBase64(diploma);
    const data = { file };
    console.log(afastInfo.id);
    await api
      .post(`/documento/novoDipl/${afastInfo.id}`, data, token)
      .then((response) => {
        getData();
        toast.success(response.data.msg);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde.");
        }
      });
  };

  return (
    <LayoutServidor>
      <ContainerServidor>
        <TituloServidorRelatorio>
          <h1>Relatórios</h1>
        </TituloServidorRelatorio>
        <BoxServidor>
          <RelatorioServidor>
            {dueDate(`${afastInfo?.dataRelatorio1}`) || afastInfo?.relatorio1 ? (
              <p>{`Ja entregue ou acabou o prazo .`}</p>
            ) : (
              <form onSubmit={uploadRelatorio1}>
                <h4>Relatório 1</h4>
                <label htmlFor="relatorio1">
                  {" "}
                  <FiUpload /> Upload{" "}
                </label>
                <input required type="file" id="relatorio1" name="relatorio1" accept=".pdf" onChange={(e) => setRelatorio1(e.target.files[0])} />
                <PreviewArquivo>
                  {relatorio1 && <img src={pdficon} alt={relatorio1.name} />}
                  <p>{relatorio1.name}</p>
                </PreviewArquivo>

                <RelatorioBotao>
                  <button type="submit">Enviar</button>
                </RelatorioBotao>
              </form>
            )}
          </RelatorioServidor>

          <RelatorioServidor>
            {dueDate(`${afastInfo?.dataRelatorio2 || ""}`) || afastInfo?.relatorio2 || "" ? (
              <p>{`Ja entregue ou acabou o prazo`}</p>
            ) : (
              <form onSubmit={uploadRelatorio2}>
                <h4>Relatório 2</h4>
                <label htmlFor="relatorio2">
                  {" "}
                  <FiUpload /> Upload{" "}
                </label>
                <input required type="file" id="relatorio2" name="relatorio2" accept=".pdf" onChange={(e) => setRelatorio2(e.target.files[0])} />
                <PreviewArquivo>
                  {relatorio2 && <img src={pdficon} alt={relatorio2.name} />}
                  <p>{relatorio2.name}</p>
                </PreviewArquivo>

                <RelatorioBotao>
                  <button type="submit">Enviar</button>
                </RelatorioBotao>
              </form>
            )}
          </RelatorioServidor>

          <RelatorioServidor>
            {dueDate(`${afastInfo?.dataRelatorio3 || ""}`) || afastInfo?.relatorio3 || "" ? (
              <p>{`Ja entregue ou acabou o prazo`}</p>
            ) : (
              <form onSubmit={uploadRelatorio3}>
                <h4>Relatório 3</h4>
                <label htmlFor="relatorio3">
                  {" "}
                  <FiUpload /> Upload{" "}
                </label>
                <input required type="file" id="relatorio3" name="relatorio3" accept=".pdf" onChange={(e) => setRelatorio3(e.target.files[0])} />
                <PreviewArquivo>
                  {relatorio3 && <img src={pdficon} alt={relatorio3.name} />}
                  <p>{relatorio3.name}</p>
                </PreviewArquivo>

                <RelatorioBotao>
                  <button type="submit">Enviar</button>
                </RelatorioBotao>
              </form>
            )}
          </RelatorioServidor>

          <RelatorioServidor>
            {dueDate(`${afastInfo?.dataRelatorio4 || ""}`) || afastInfo?.relatorio4 || "" ? (
              <p>{`Ja entregue ou acabou o prazo`}</p>
            ) : (
              <form onSubmit={uploadRelatorio4}>
                <h4>Relatório 4</h4>
                <label htmlFor="relatorio4">
                  {" "}
                  <FiUpload /> Upload{" "}
                </label>
                <input required type="file" id="relatorio4" name="relatorio4" accept=".pdf" onChange={(e) => setRelatorio4(e.target.files[0])} />
                <PreviewArquivo>
                  {relatorio4 && <img src={pdficon} alt={relatorio4.name} />}
                  <p>{relatorio4.name}</p>
                </PreviewArquivo>

                <RelatorioBotao>
                  <button type="submit">Enviar</button>
                </RelatorioBotao>
              </form>
            )}
          </RelatorioServidor>
        </BoxServidor>

        <TituloServidorDiploma>
          <h1>Diploma</h1>
        </TituloServidorDiploma>

        <RelatorioServidor>
          {dueDate(`${afastInfo?.dataDiploma}`) || afastInfo?.diploma ? (
            <p>{`Ja entregue ou acabou o prazo`}</p>
          ) : (
            <form onSubmit={uploadDiploma}>
              <h4>Diploma</h4>
              <label htmlFor="diploma">
                {" "}
                <FiUpload /> Upload{" "}
              </label>
              <input required type="file" id="diploma" name="diploma" accept=".pdf" onChange={(e) => setDiploma(e.target.files[0])} />
              <PreviewArquivo>
                {diploma && <img src={pdficon} alt={diploma.name} />}
                <p>{diploma.name}</p>
              </PreviewArquivo>

              <RelatorioBotao>
                <button type="submit">Enviar</button>
              </RelatorioBotao>
            </form>
          )}
        </RelatorioServidor>

        <BotaoServidor>
          <a href="/main">Voltar</a>
        </BotaoServidor>
      </ContainerServidor>
    </LayoutServidor>
  );
};

export default PaginaServidor;

// <form onSubmit={uploadDiploma}>
// <h4>Diploma</h4>
// <label htmlFor="diploma"> <FiUpload/> Upload</label>
// <input required type="file" id="diploma" accept=".pdf" onChange={e => setDiploma(e.target.files[0])} />

// <RelatorioBotao><button type="submit">Enviar</button></RelatorioBotao>
// </form>

