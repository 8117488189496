import styled from "styled-components"


export const LayoutStyleLogin = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: stretch;
`;

//Lado esquerdo
export const ContainerLeftStyleLogin = styled.div`
    background-color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    
`;

//Lado direito
export const ContainerRightStyleLogin = styled.div `
    background-color:#690000;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    img {
        width: 39%;
        height: 38%;
        object-fit: contain;
        
    }
`;

export const BoxStyleLogin = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 30px;
    border-radius: 3px;
    background-color: white;
    h2{
        margin-bottom: 5px;
    }
    p{
        margin-bottom: 20px;
        font-size: 0.9rem;
    }
    
    label{
        font-size: 0.8rem;
        font-weight: 500;
        margin-bottom: 6px;
    }

    input{
        position: relative;
        width: 100%;
        padding: 15px;
        margin-bottom: 15px;
        outline: none;
        background-color: whitesmoke;
        border-radius: 3px;
        font-size: 13px;
        margin-top: 2px;
    }

`
export const ImagemPGCA = styled.div`
    display: flex;
    justify-content: center;
    img{
        height: 20%;
        width: 60%;
        object-fit: contain;
        margin-bottom: 25px;
    }
`
//rgb(90,2,31)
export const BotaoLoginStyle = styled.div`
    display: flex;
    justify-content: flex-end;
    button{
        padding: 10px;
        color: white;
        background-color: rgb(90,2,31); 
        border-radius: 3px;
        &:hover{
            cursor: pointer;
            background-color: rgb(90,2,31,0.9)
        }
    }
    
    
`
