import React, { useCallback } from "react";
import { LayoutDiploma, ContainerDiploma, TituloDiploma, TableDiploma } from "./style.js";
import { useState, useEffect } from "react";
import { api } from "../../services/api";
import { useToken } from "../../contexts/useToken";

function AcompanhaDiploma() {
  const { token } = useToken();

  const [loadingData, setLoadingData] = useState(true);
  const [diploma, setDiploma] = useState([]);
  const [usuarios, setUsuario] = useState([]);

  useEffect(() => {
    async function getData() {
      await api.get("/usuario/servidor", token).then((response) => {
        setUsuario(response.data);
        setLoadingData(false);
      });

      await api.get("/afastamento/afastamento", token).then((response) => {
        setDiploma(response.data);
        setLoadingData(false);
      });
    }

    if (loadingData) {
      getData();
    }
  }, [loadingData, token]);

  const userNome = (id) => {
    const result = usuarios.find((usuario) => usuario.id === id);
    const nome = result?.nome;
    return nome;
  };

  const downloadFile = useCallback(
    async (diplomaId) => {
      api.get(`/documento/downloadDiploma/${diplomaId}`, token).then((response) => {
        const pdf = response.data;

        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `${diplomaId}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        downloadLink.remove();
      });
    },
    [token]
  );

  return (
    <LayoutDiploma>
      <ContainerDiploma>
        <TituloDiploma>Acompanhar entrega de diploma</TituloDiploma>
        <TableDiploma>
          <thead>
            <tr>
              <th>Servidor</th>
              <th>Fim do afastamento</th>
              <th>Fim da entrega de diploma</th>
              <th>Diploma</th>
            </tr>
          </thead>
          <tbody>
            {diploma.map((diploma) => (
              <tr key={diploma.id}>
                <td>{userNome(diploma.idUsuario)}</td>
                <td>{diploma?.dataFinal || ""}</td>
                <td>{diploma?.dataDiploma || ""} </td>
                <td>
                  {diploma?.diploma || "" ? (
                    <button onClick={() => downloadFile(diploma.diploma)} style={{ color: "green", cursor: "pointer", backgroundColor: "inherit" }} value={diploma.diploma} name="diploma">
                      Diploma
                    </button>
                  ) : (
                    <p style={{ color: "red" }}> Diploma</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </TableDiploma>
      </ContainerDiploma>
    </LayoutDiploma>
  );
}
export default AcompanhaDiploma;

// {
//   diploma.map((diploma) => (
//     <tr key={diploma.id}>
//       <td>{userNome(diploma.idUsuario)}</td>
//       <td>{diploma?.dataFinal || ""}</td>
//       <td>{diploma?.dataDiploma || ""} </td>
//       <td>
//         {diploma?.diploma || "" ? (

//           <a
//             style={{ color: "green" }}
//             value={diploma.diploma}
//             name="diploma"
//             href={`${(stringbase64(diploma.diploma))}`}
//             download={`${diploma.diploma}.pdf`}

//           >
//             Diploma
//           </a>

//         ) : (
//           <p style={{ color: "red" }}> Diploma</p>
//         )}
//       </td>
//     </tr>
//   ))
// }
