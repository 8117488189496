import {
  LayoutVisualizaRelatorio, ContainerVisualizaRelatorio, TituloVisualizaRelatorio, ContainerPDF,
  ContainerTexto, TextoComentario, TextoStatus, ContainerDetalhes, TextoBotao
} from "./style.js";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useToken } from "../../contexts/useToken";

function VisualizaRelatorio() {
  const { token } = useToken();
  const { id } = useParams();
  const [loadingData, setLoadingData] = useState(true);

  const [relatorio, setRelatorio] = useState();
  const [comentario, setComentario] = useState();
  const [status, setStatus] = useState();

  const VisualizarRelatorio = async (e) => {
    e.preventDefault();

    const data = { IdRelatorio: id, comentario, status };
    await api
      .post("/documento/parecer", data, token)
      .then((response) => {
        toast.success(response.data.msg);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde!");
        }
      });
  };

  useEffect(() => {
    async function getData() {
      await api.get(`/documento/relatorio/${id}`, token).then((response) => {
        setRelatorio(response.data);
        setLoadingData(false);
      });
    }

    if (loadingData) {
      getData();
    }
  }, [id, loadingData, token]);

  const downloadFile = useCallback(
    async (diplomaId) => {
      api.get(`/documento/downloadRelatorio/${diplomaId}`, token).then((response) => {
        const pdf = response.data;

        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `${diplomaId}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        downloadLink.remove();
      });
    },
    [token]
  );

  return (
    <>
      <LayoutVisualizaRelatorio>
        <ContainerVisualizaRelatorio>
          <TituloVisualizaRelatorio>
            <h1>Visualização do Relatório</h1>
          </TituloVisualizaRelatorio>
          <ContainerDetalhes>
            <h4>Detalhes</h4>
            <table>
              <tbody>
                <tr>
                  <th scope="row">Servidor:</th>
                  <td>{relatorio?.usuarioInfo?.nome || ""}</td>
                </tr>
                <tr>
                  <th scope="row">Instituição:</th>
                  <td>{relatorio?.afastamentoInfo?.instAfastamento || ""}</td>
                </tr>
                <tr>
                  <th scope="row">Área de Estudo:</th>
                  <td>{relatorio?.afastamentoInfo?.areaEstudo || ""}</td>
                </tr>
              </tbody>
            </table>
          </ContainerDetalhes>

          {
            <button onClick={() => downloadFile(id)} style={{ color: "green", cursor: "pointer", backgroundColor: "inherit" }} value={id} name="diploma">
              Clique para baixar o relatorio
            </button>
          }

          <form onSubmit={VisualizarRelatorio}>
            <ContainerPDF>
              {/*<AllPagesPDFViewer pdf={exemploPDF}/>*/}
            </ContainerPDF>
            <ContainerTexto>
              <TextoComentario>
                <label htmlFor="comentario">Comentários:</label>
                <textarea id="comentario" name="comentario" rows="5" cols="85" value={comentario} onChange={(e) => setComentario(e.target.value)}></textarea>
              </TextoComentario>

              <TextoStatus>
                <label>
                  Status do relatório:
                  <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option>Selecione</option>
                    <option value={true}>Aprovado</option>
                    <option value={false}>Não Aprovado</option>
                  </select>
                </label>
              </TextoStatus>
              {status && (
                <TextoBotao>
                  <button type="submit">Enviar</button>
                </TextoBotao>
              )}
            </ContainerTexto>
          </form>
        </ContainerVisualizaRelatorio>
      </LayoutVisualizaRelatorio>
    </>
  );
}
export default VisualizaRelatorio;
