import styled from "styled-components"


export const LayoutCadastroAfastamentoStyle = styled.div`
    * {
    padding:0;
    margin:0;
    vertical-align:baseline;
    list-style:none;
    border:0
}
    display: flex;
    align-items: stretch;
    justify-content: start;

`;
export const ContainerCadastroAfastamentoStyle = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    height: auto;
    padding: 50px;
    h1{
        font-size: 25px;
        margin-bottom: 15px;
    }
    label{
        font-size: 13px;
        margin-bottom: 5px;
        font-weight: 500;
    }
    input{
        position: relative;
        width: 100%;
        padding: 15px;
        margin-bottom: 15px;
        outline: none;
        background-color: whitesmoke;
        border-radius: 3px;
        font-size: 13px;
        margin-top: 2px;
    }
    select{
        display: block;
        padding: 10px;
        position: relative;
    }
    button{
        margin-top:20px;
        padding: 10px;
        border-radius: 3px;
        font-size:12px;
        background-color: rgb(90,2,31);
        color:white;
        width:fit-content;
        margin-right: 5px;
        &:hover{
            background-color: rgb(90,2,31, 0.9);
            color: white;
            cursor: pointer;
        }
    
    }
    
`;
export const DataStyle = styled.div`
    display: flex;
    margin-top:10px;
    align-items: center;
    label{
        margin-right: 10px;
    }
   
    p{
       margin: auto;
    }
`;

export const BotaoAfastamentoStyle = styled.div`
    display: flex;
    justify-content: flex-end;
`;