import styled from "styled-components"


export const LayoutNovoUsuarioStyle = styled.div`
    * {
    padding:0;
    margin:0;
    vertical-align:baseline;
    list-style:none;
    border:0
}
    display: flex;
    justify-content: start;
    flex-direction: column;

`;

export const FormNovoUsuarioStyle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 70%;
    height: auto;
    background-color: transparent;
    h1{
        font-size: 25px;
        margin-bottom: 15px;
    }
    h4{
        font-size: 18px;
        margin: 15px 0;
    }
    label{
        font-size: 13px;
        margin-bottom: 5px;
        font-weight: 500;
        
    }
    input{
        display: block;
        width: 100%;
        padding: 15px;
        margin-bottom: 15px;
        outline: none;
        background-color: whitesmoke;
        border-radius: 3px;
        font-size: 13px;
        margin-top: 2px;
    }
    select{
        display: block;
        padding: 0.7em;
        border-radius: 0;
        border: none;
    }

    button{
        margin-top:20px;
        padding: 10px;
        border-radius: 3px;
        font-size:12px;
        background-color: rgb(90,2,31);
        color:white;
        width:fit-content;
        margin-right: 5px;
        &:hover{
            background-color: rgb(90,2,31, 0.9);
            color: white;
            cursor: pointer;
        }
    }
    
`;

export const BotaoCadastroSyle = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: fit-content;
    margin-bottom: 20px;
    
`;