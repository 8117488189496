import React from "react";
import { useState } from "react";
import { LayoutForm, ContainerForm, BotaoForm } from "./style.js";
import { api } from "../../services/api.js";
import { toast } from "react-toastify";
import { useToken } from "../../contexts/useToken";

function AlteraSenha() {
  const { token } = useToken();

  const [alterasenha, setAlteraSenha] = useState({
    senhaAntiga: "",
    senha: "",
    confSenha: "",
  });

  const onChange = (e) => {
    setAlteraSenha({ ...alterasenha, [e.target.name]: e.target.value });
  };

  const AlterarSenha = async (e) => {
    e.preventDefault();
    await api
      .put("/usuario/novasenha/", alterasenha, token)
      .then((response) => {
        toast.success(response.data.msg);
        setAlteraSenha({
          senhaAntiga: "",
          senha: "",
          confSenha: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("Erro: Tente mais tarde.");
        }
      });
  };

  return (
    <LayoutForm>
      <ContainerForm>
        <form onSubmit={AlterarSenha}>
          <h1> Alterar Senha </h1>

          <label htmlFor="senhaAntiga">
            {" "}
            Senha atual:
            <input type="password" id="senhaAntiga" name="senhaAntiga" placeholder="Insira sua senha antiga" value={alterasenha.senhaAntiga} onChange={onChange} />
          </label>
          <label htmlFor="senha">
            {" "}
            Senha nova:
            <input type="password" id="senha" name="senha" placeholder="Insira sua nova senha" value={alterasenha.senha} onChange={onChange} />
          </label>

          <label htmlFor="confSenha">
            {" "}
            Confirme nova senha:
            <input type="password" id="confSenha" name="confSenha" placeholder="Insira sua nova senha" value={alterasenha.confSenha} onChange={onChange} />
          </label>
          <BotaoForm>
            <button type="submit">Cadastrar</button>
          </BotaoForm>
        </form>
      </ContainerForm>
    </LayoutForm>
  );
}
export default AlteraSenha;
